<template>
  <section class="agent-buttons">
    <a class="primary-button primary-button__new" :href="agentNew" target="_blank">agent new</a>
    <a class="primary-button primary-button__classic" :href="agentClassic" target="_blank">agent classic</a>
    <img  class="brand" style="margin-top: 6%; width: 100px;" src="../assets/images/BeatTheTotalWhite.svg" alt="wagerInternational">
  </section>
  
</template>

<script>
  export default {
    name: "AgentsButtons",
    setup() {
      const agentClassic = "https://agents.beatthetotal.online/";
      const agentNew = "https://adm.beatthetotal.online/home/landing";
      return {
        agentClassic,
        agentNew,
      }
    },
  };
</script>
